/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from "react";
import Header from "../../Components/Header";
import AddRepositoryModal from "./AddRepositoryModal";
import Apiutils from "../../api/Apiutils";
import Toaster from "../../Components/Toaster";
import Pagination from "../../Components/Pagination";
import moment from "moment";
import { FaSpinner } from "react-icons/fa";
import { debounce } from "../../Components/Debounce";
import ViewModal from "./ViewModal";
import StatusBadge from "../../Components/StatusBadge";
import Pusher from "pusher-js";

const Dashboard = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [list, setList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState();
  const [isloading, setIsLoading] = useState(false);
  const [viewModal, setViewModal] = useState(false);
  const [repoData, setRepoData] = useState();

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const repoLists = () => {
    setIsLoading(true);
    Apiutils.repoList(`page=${currentPage}&page_size=10&search=${searchTerm}`)
      .then((res) => {
        setTotalRecords(res?.data?.total);
        setList(res?.data?.data);
        setIsLoading(false);
      })
      .catch((err) => {
        Toaster(err?.data?.message, "error");
        setIsLoading(false);
      });
  };

  useEffect(() => {
    const pusher = new Pusher("297c56a4be33eac0ae9b", {
      cluster: "ap2",
    });

    const channel = pusher.subscribe("test");
    channel.bind("gitStatus", function (data) {
      setList((prevList) =>
        prevList.map((item) =>
          item.id === data.id ? { ...item, status: data.status } : item
        )
      );
    });

    return () => {
      pusher.unsubscribe("test");
    };
  }, [list]);

  // Function to handle page change
  const handlePageChange = (page) => {
    setCurrentPage(page);
    // You can perform additional actions here, like fetching data for the new page
  };
  const handleSearchChange = (value) => {
    setSearchTerm(value);
  };

  const handleRepoDetail = (data) => {
    setViewModal(true);
    setRepoData(data);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onSearch = useCallback(debounce(handleSearchChange), [list]);

  useEffect(() => {
    repoLists();
  }, [currentPage, searchTerm]);

  return (
    <>
      <Header />
      <div className="container w-2/3 mt-4 mx-auto">
        <div className="flex justify-between">
          <button
            onClick={openModal}
            className="w-1/6 mb-3 bg-gradient-to-r from-blue-800 to-indigo-900 justify-center hover:bg-purple-700 text-white py-2 rounded-md focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-opacity-50"
          >
            Add Repository
          </button>
          <form>
            <label
              htmlFor="default-search"
              className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white"
            >
              Search
            </label>
            <div className="relative">
              <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                <svg
                  className="w-4 h-4 text-gray-500 dark:text-gray-400"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 20 20"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                  />
                </svg>
              </div>
              <input
                className="border-2 ps-8 border-gray-300 bg-white h-10 px-5 pr-4 rounded-lg text-sm focus:outline-none"
                type="search"
                name="search"
                defaultValue={searchTerm}
                onChange={(e) => onSearch(e.target.value)}
                placeholder="Search by name..."
              />
            </div>
          </form>
        </div>
        <table className="table-auto w-full">
          <thead>
            <tr className="bg-gray-200 text-left">
              <th className="px-4 py-2">Name</th>
              <th className="px-4 py-2">Status</th>
              <th className="px-4 py-2 flex justify-end">Created</th>
            </tr>
          </thead>
          <tbody>
            {isloading ? (
              <tr>
                <td colSpan="3" className="text-center py-8">
                  <div className="flex items-center justify-center">
                    <FaSpinner className="animate-spin mr-2" />
                    <span>Loading...</span>
                  </div>
                </td>
              </tr>
            ) : list && list.length > 0 ? (
              list.map((item) => (
                <tr key={item.id} className="border-b hover:bg-gray-100">
                  <td
                    className="px-4 py-2 hover:text-blue-500 hover:underline hover:cursor-pointer"
                    onClick={() => handleRepoDetail(item)}
                  >
                    <img
                      src="https://github.githubassets.com/images/modules/logos_page/GitHub-Mark.png"
                      alt="GitHub Logo"
                      className="w-6 h-6 inline-block mr-2"
                    />
                    {item?.name}
                  </td>
                  <td className="px-4 py-2">
                    {item?.status !== undefined && (
                      <StatusBadge status={item.status} />
                    )}
                  </td>
                  <td className="px-4 py-2 flex justify-end">
                    {moment(item?.created_at).format("DD-MM-YYYY h:mm A")}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="3" className="text-center py-8">
                  No data found.
                </td>
              </tr>
            )}
          </tbody>
        </table>
        {totalRecords > 10 && (
          <div className="flex justify-end">
            <Pagination
              totalItems={totalRecords}
              itemsPerPage={10}
              onPageChange={handlePageChange}
            />
          </div>
        )}
      </div>
      <AddRepositoryModal
        isOpen={isModalOpen}
        closeModal={closeModal}
        repoList={repoLists}
      />{" "}
      <ViewModal
        isOpen={viewModal}
        setModalClose={setViewModal}
        repoDetails={repoData}
        repoList={repoLists}
      />
    </>
  );
};

export default Dashboard;
