import ActivationPage from "../Pages/Activation/ActivationPage";
import Login from "../Pages/Authentication/Login";
import Register from "../Pages/Authentication/Register";
import ResetPassword from "../Pages/Authentication/ResetPassword";
import Dashboard from "../Pages/Dashboard/Dashboard";

const authRoutes = [{ path: "/dashboard", component: Dashboard }];
const publicRoutes = [
  { path: "/login", component: Login },
  { path: "/register", component: Register },
  { path: "/reset-password/:token", component: ResetPassword },
  { path: "/activation/:token", component: ActivationPage },
];

export { authRoutes, publicRoutes };
