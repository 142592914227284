import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Header from "../../Components/Header";
import { useFormik } from "formik";
import * as Yup from "yup";
import Apiutils from "../../api/Apiutils";
import Toaster from "../../Components/Toaster";
import { FaSpinner } from "react-icons/fa";

const ResetPassword = () => {
  const navigate = useNavigate();
  const { token } = useParams();
  const [isLoading, setisLoading] = useState(false);
  const formik = useFormik({
    initialValues: {
      password: "",
      confirmPassword: "",
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .required("Password is required")
        .min(6, "Password must be at least 6 characters"),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref("password"), ""], "Passwords must match")
        .required("Confirm Password is required"),
    }),
    onSubmit: (values) => {
      const apiData = {
        password: values.password,
        token: token,
      };
      setisLoading(true);
      Apiutils.resetPassword(apiData)
        .then((res) => {
          Toaster(res?.data?.message, "success");
          setisLoading(false);
          navigate("/login");
        })
        .catch((err) => {
          Toaster(err?.data?.message, "error");
          setisLoading(false);
        });
    },
  });
  return (
    <div className="flex flex-col bg-gray-800 bg-opacity-45 items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
      <Header />
      <div className="bg-white rounded-t-lg shadow-lg max-w-md w-full p-4">
        <h2 className="text-xl font-bold mb-2 text-gray-800">Reset Password</h2>
      </div>
      <div className="bg-white mt-[1px] rounded-b-lg shadow-lg max-w-md w-full p-4">
        <form onSubmit={formik.handleSubmit}>
          <div className="mb-4">
            <input
              type="password"
              id="name"
              name="password"
              className={`mt-1 block w-full px-3 text-center py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-purple-500 focus:border-transparent ${
                formik.errors.password && formik.touched.password
                  ? "border-red-500"
                  : "border-gray-300"
              }`}
              placeholder="New Passwod"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.password}
            />
            {formik.errors.password && formik.touched.password && (
              <span className="text-red-500 text-sm">
                {formik.errors.password}
              </span>
            )}
          </div>

          <div className="mb-6">
            <input
              type="password"
              id="confirmPassword"
              name="confirmPassword"
              className={`mt-1 block w-full px-3 text-center py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-purple-500 focus:border-transparent ${
                formik.errors.confirmPassword && formik.touched.confirmPassword
                  ? "border-red-500"
                  : "border-gray-300"
              }`}
              placeholder="Confirm Password"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.confirmPassword}
            />
            {formik.errors.confirmPassword &&
              formik.touched.confirmPassword && (
                <span className="text-red-500 text-sm">
                  {formik.errors.confirmPassword}
                </span>
              )}
          </div>
          <div className="flex justify-center">
            <button
              type="submit"
              className={`flex items-center w-1/3 bg-gradient-to-r from-blue-800 to-indigo-900 justify-center hover:bg-purple-700 text-white py-2 rounded-md focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-opacity-50 ${
                isLoading ? "opacity-50 cursor-not-allowed" : ""
              }`}
            >
              {isLoading ? <FaSpinner className="animate-spin mr-2" /> : null}
              Reset Password
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ResetPassword;
