import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../../Components/Header";
import { useFormik } from "formik";
import { object, string } from "yup";
import Apiutils from "../../api/Apiutils";
import OtpModal from "./OtpModal";
import Toaster from "../../Components/Toaster";
import { FaSpinner } from "react-icons/fa";
import ReactivateModal from "./ReactivateModal ";
import ActionButton from "../../Components/ActionButton";

const Login = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [userId, setUserId] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [forgotLoading, setForgotLoading] = useState(false);
  const [isReactivateModalOpen, setIsReactivateModalOpen] = useState(false);
  const [reactivValues, setReactiveValues] = useState();
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: object().shape({
      email: string()
        .required("Email is required")
        .email("Invalid email address"),
      password: string().required("Password is required"),
    }),
    onSubmit: (values) => {
      setIsLoading(true);
      const apiData = {
        email: values.email,
        password: values.password,
      };
      Apiutils.login(apiData)
        .then((res) => {
          setIsLoading(false);
          if (res.status === 200) {
            setIsModalOpen(true);
            setUserId(res?.data?.data?.id);
            Toaster("Otp sent successfully to your Email", "success");
          }
        })
        .catch((err) => {
          setIsLoading(false);
          if (err.status === 423) {
            setIsReactivateModalOpen(true);
            setReactiveValues(values);
          } else {
            Toaster(err?.data?.message, "error");
          }
        });
    },
  });

  const handleForgotPassword = () => {
    if (!formik.values.email) {
      formik.setFieldError("email", "Email is required");
      formik.setFieldTouched("email", true);
    } else {
      setForgotLoading(true);
      Apiutils.sendResetLink({ email: formik.values.email })
        .then((res) => {
          Toaster(res.data.message, "success");
          formik.setFieldError("email", "");
          formik.setFieldTouched("email", false);
          setForgotLoading(false);
        })
        .catch((err) => {
          Toaster(err.data.message, "error");
        });
    }
  };

  return (
    <>
      <div className="flex flex-col bg-gray-800 bg-opacity-45 items-center justify-center px-6 py-20 mx-auto md:h-screen lg:py-0">
        <Header />
        <div className="bg-white rounded-t-lg shadow-2xl max-w-md w-full p-4">
          <h2 className="text-xl font-bold mb-2 text-gray-800">Sign in</h2>
        </div>
        <div className="bg-white mt-[1px] rounded-b-lg shadow-lg max-w-md w-full p-4">
          <form onSubmit={formik.handleSubmit}>
            <div className="mb-4">
              <input
                type="email"
                id="email"
                name="email"
                className={`mt-1 block w-full px-3 text-center py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-purple-500 focus:border-transparent ${
                  formik.errors.email && formik.touched.email
                    ? "border-red-500"
                    : "border-gray-300"
                }`}
                placeholder="Email"
                autoComplete="off"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.errors.email && formik.touched.email && (
                <span className="text-red-500 text-sm">
                  {formik.errors.email}
                </span>
              )}
            </div>
            <div className="mb-1">
              <input
                type="password"
                id="password"
                name="password"
                className={`mt-1 block w-full px-3 text-center py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-purple-500 focus:border-transparent ${
                  formik.errors.password && formik.touched.password
                    ? "border-red-500"
                    : "border-gray-300"
                }`}
                placeholder="Password"
                autoComplete="off"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.errors.password && formik.touched.password && (
                <span className="text-red-500 text-sm">
                  {formik.errors.password}
                </span>
              )}
            </div>
            <div className="flex justify-end mb-2">
              <span
                className={` flex items-center cursor-pointer text-purple-500 hover:underline ${
                  forgotLoading ? "opacity-50 cursor-not-allowed" : ""
                }`}
                onClick={handleForgotPassword}
              >
                {forgotLoading ? (
                  <FaSpinner className="animate-spin mr-2" />
                ) : null}
                Forgot Password?
              </span>
            </div>
            <div className="flex justify-center mt-6">
              <ActionButton
                type="submit"
                isLoading={isLoading}
                disabled={isLoading}
                className="w-1/4 bg-gradient-to-r from-blue-800 to-indigo-900 hover:bg-purple-700 text-white"
              >
                Login
              </ActionButton>
            </div>
          </form>
          <p className="mt-4 text-sm text-center text-gray-600">
            Don't have an account?{" "}
            <button
              onClick={() => navigate("/register")}
              className="text-purple-500 hover:underline"
            >
              Register
            </button>
          </p>
        </div>
      </div>
      <OtpModal
        isOpen={isModalOpen}
        closeModal={() => setIsModalOpen(false)}
        userId={userId}
      />
      <ReactivateModal
        isOpen={isReactivateModalOpen}
        closeModal={() => setIsReactivateModalOpen(false)}
        values={reactivValues}
      />
    </>
  );
};

export default Login;
