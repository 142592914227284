import moment from "moment";
import React, { useState } from "react";
import Apiutils from "../../api/Apiutils";
import Toaster from "../../Components/Toaster";
import ActionButton from "../../Components/ActionButton";

const RepoDetails = ({ repoDetails, handleCloseModal, getRepoList }) => {
  const [isSssh, setisSsh] = useState(false);
  const [isLoading, setisLoading] = useState(false);
  const [isBuildLoading, setisBuildLoading] = useState(false);
  const [isDeleteLoading, setisDeleteLoading] = useState(false);
  const [sshKey, setSshKey] = useState();

  const handleSsh = () => {
    setisLoading(true);
    Apiutils.generateSSH({ id: repoDetails?.id })
      .then((res) => {
        setSshKey(res?.data?.data?.ssh_key);
        setisSsh(true);
        setisLoading(false);
        Toaster(res?.data?.message, "success");
        getRepoList();
      })
      .catch((err) => {
        Toaster(err?.data?.message, "error");
        setisLoading(false);
      });
  };

  function convertSshUrlToSettingsUrl(sshUrl) {
    // Extract the username and repository from the SSH URL
    const repoPath = sshUrl.replace("git@github.com:", "").replace(".git", "");

    // Construct the new URL
    const settingsUrl = `https://github.com/${repoPath}/settings/keys/new#new_public_key`;

    return settingsUrl;
  }

  const handleDeleteRepo = () => {
    setisDeleteLoading(true);
    Apiutils.deleteBuild({ id: repoDetails?.id })
      .then((res) => {
        Toaster(res?.data?.message, "success");
        setisDeleteLoading(false);
        getRepoList();
        handleCloseModal();
      })
      .catch((err) => {
        Toaster(err?.data?.message, "error");
        setisDeleteLoading(false);
      });
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(sshKey);
    Toaster("SSH key copied to clipboard", "success");
  };

  const handleBuild = () => {
    setisBuildLoading(true);
    Apiutils.createBuild({ id: repoDetails?.id })
      .then((res) => {
        Toaster(res?.data?.message, "success");
        setisBuildLoading(false);
        getRepoList();
        handleCloseModal();
      })
      .catch((err) => {
        Toaster(err?.data?.message, "error");
        setisBuildLoading(false);
        getRepoList();
      });
  };

  const handleClose = () => {
    handleCloseModal();
    setisSsh(false);
  };

  return (
    <>
      <div className="flex flex-col space-y-2 ">
        <p>
          <span className="font-semibold">Name:</span> {repoDetails?.name}
        </p>
        <p>
          <span className="font-semibold">Source URL:</span>{" "}
          {repoDetails?.source_url}
        </p>
        <p>
          <span className="font-semibold">Created:</span>{" "}
          {moment(repoDetails?.created_at).format("DD-MM-YYYY h:mm A")}
        </p>
        {isSssh && (
          <textarea
            className="w-full text-xs h-28 overflow-auto resize-none p-2 border border-gray-200 rounded-md focus:outline-none focus:ring-1 focus:border-gradient-to-r from-blue-800 to-indigo-900"
            value={sshKey}
            readOnly
          />
        )}
      </div>

      {(() => {
        if (repoDetails.type === 1 || repoDetails?.ssh_status === 1) {
          return (
            <div className="flex justify-center space-x-4 mt-8">
              <ActionButton
                onClick={handleClose}
                disabled={false}
                className="w-1/3 py-2 px-4 rounded-md mr-2 border border-blue-800 text-gray-800 hover:text-gray-900"
              >
                Cancel
              </ActionButton>
              <ActionButton
                onClick={handleBuild}
                isLoading={isBuildLoading}
                disabled={isBuildLoading}
                className="w-1/3 bg-gradient-to-r from-blue-800 to-indigo-900 hover:bg-purple-700 text-white"
              >
                Build
              </ActionButton>
              <ActionButton
                onClick={handleDeleteRepo}
                isLoading={isDeleteLoading}
                disabled={isDeleteLoading}
                className="w-1/3 py-2 px-4 rounded-md border border-blue-800 text-gray-800 hover:text-gray-900"
              >
                Remove
              </ActionButton>
            </div>
          );
        } else if (isSssh) {
          return (
            <>
              <div className="flex justify-center space-x-4 mt-8">
                <ActionButton
                  onClick={handleCopy}
                  disabled={false}
                  className="w-1/3 bg-gradient-to-r from-blue-800 to-indigo-900 hover:bg-purple-700 text-white"
                >
                  Copy SSH
                </ActionButton>
              </div>
              <div className="flex justify-center mt-4 mb-4 text-blue-800 hover:text-purple-700">
                <a
                  href={convertSshUrlToSettingsUrl(repoDetails?.source_url)}
                  target="_blank"
                  rel="noreferrer"
                >
                  Open repository Deploy keys on GitHub.
                </a>
              </div>
              <div className="flex justify-center space-x-4 mt-5">
                <ActionButton
                  onClick={handleClose}
                  className="w-1/3 py-2 px-4 rounded-md mr-2 border border-blue-800 text-gray-800 hover:text-gray-900"
                >
                  Cancel
                </ActionButton>
                <ActionButton
                  onClick={handleBuild}
                  isLoading={isBuildLoading}
                  disabled={isBuildLoading}
                  className="w-1/3 bg-gradient-to-r from-blue-800 to-indigo-900 hover:bg-purple-700 text-white"
                >
                  Build
                </ActionButton>
              </div>
            </>
          );
        } else {
          // Default case
          return (
            <div className="flex justify-center space-x-4 mt-8">
              <ActionButton
                onClick={handleClose}
                className="w-1/3 py-2 px-4 rounded-md mr-2 border border-blue-800 text-gray-800 hover:text-gray-900"
              >
                Cancel
              </ActionButton>
              <ActionButton
                onClick={handleSsh}
                isLoading={isLoading}
                disabled={isLoading}
                className="w-1/3 bg-gradient-to-r from-blue-800 to-indigo-900 hover:bg-purple-700 text-white"
              >
                Convert to SSH
              </ActionButton>
              <ActionButton
                onClick={handleDeleteRepo}
                isLoading={isDeleteLoading}
                disabled={isDeleteLoading}
                className="w-1/3 py-2 px-4 rounded-md border border-blue-800 text-gray-800 hover:text-gray-900"
              >
                Remove
              </ActionButton>
            </div>
          );
        }
      })()}
    </>
  );
};

export default RepoDetails;
