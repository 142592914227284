import { createSlice } from "@reduxjs/toolkit";


const token = JSON.parse(localStorage.getItem("gitToken"));

const loginSlice = createSlice({
  name: "login",
  initialState: {
    login: token?.data,
  },
  reducers: {
    setLoginAuth: (state, action) => {
      state.login = action.payload;
    },
  },
});

export const { setLoginAuth } = loginSlice.actions;
export default loginSlice.reducer;
