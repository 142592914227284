import axios from "axios";
import { BASE_URL } from "../utils/AppConfig";
import store from "../redux/store";
import { setLoginAuth } from "../redux/slices/auth/loginSlice";

const api = axios.create({
  baseURL: BASE_URL,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
  },
});

api.interceptors.request.use(
  (config) => {
    const token = JSON.parse(localStorage.getItem("gitToken"));
    if (token) {
      config.headers["Authorization"] = `Bearer ${token?.data}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error?.response?.status === 401) {
      store.dispatch(setLoginAuth(""));
      localStorage.removeItem("gitToken");
    }
    return Promise.reject(error);
  }
);
export default api;