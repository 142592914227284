import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { FaSpinner } from "react-icons/fa";
import * as yup from "yup";
import Apiutils from "../api/Apiutils";
import Toaster from "./Toaster";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const ProfileModal = ({ isOpen, setOpenModal }) => {
  const [isChangePassword, setIspasswordChanges] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [userData, setUserData] = useState();
  const navigate = useNavigate();
  const data = useSelector((state) => state.loginSlice.login);
  const isProtectedRoute = data;

  const formik = useFormik({
    initialValues: {
      current: "",
      new: "",
    },
    validationSchema: yup.object().shape({
      current: yup.string().required("Current Password is Required"),
      new: yup.string().required("New Password Required"),
    }),
    onSubmit: (value) => {
      setIsLoading(true);
      const apiData = {
        old_password: value?.current,
        new_password: value?.new,
      };
      Apiutils.changePassword(apiData)
        .then((res) => {
          Toaster(res?.data?.message, "success");
          closeModal();
          setIsLoading(false);
        })
        .catch((err) => {
          Toaster(err?.data?.message, "error");
          setIsLoading(false);
        });
    },
  });

  const closeModal = () => {
    setOpenModal(false);
    setIspasswordChanges(false);
    formik.resetForm();
  };

  useEffect(() => {
    if (isProtectedRoute) {
      Apiutils.getProfile()
        .then((res) => {
          setUserData(res?.data);
          Toaster(res.data.message, "success");
        })
        .catch((err) => {
          Toaster(err.data.message, "error");
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDeleteUser = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        Apiutils.deleteAccount()
          .then((res) => {
            Swal.fire({
              title: "Deleted!",
              text: res?.data?.message,
              icon: "success",
            });
            localStorage.removeItem("gitToken");
            navigate("/");
          })
          .catch((err) => {
            Toaster(err?.data?.message, "error");
          });
      }
    });
  };

  return (
    <div
      className={`fixed inset-0 z-50 bg-black bg-opacity-50 flex flex-col items-center justify-center ${
        isOpen ? "" : "hidden"
      }`}
    >
      <div className="bg-white rounded-t-lg flex justify-between shadow-2xl max-w-md w-full p-4">
        <h2 className="text-xl font-bold">Profile</h2>
        <div className="text-2xl cursor-pointer" onClick={closeModal}>
          x
        </div>
      </div>
      <div className="bg-white mt-[1px] rounded-b-lg shadow-lg max-w-md w-full p-4">
        {isChangePassword ? (
          <form onSubmit={formik.handleSubmit}>
            <label className="font-bold font-serif">Current Password:</label>
            <div className="mb-4">
              <input
                type="password"
                id="username"
                name="current"
                className="mt-1 block w-full px-3 text-center py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-purple-500 focus:border-transparent"
                autoComplete="off"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                defaultValue={formik.values.current}
              />
              {formik.errors.current && formik.touched.current && (
                <span className="text-red-500 text-sm">
                  {formik.errors.current}
                </span>
              )}
            </div>
            <label className="font-bold font-serif">New Password:</label>
            <div className="mb-4">
              <input
                type="password"
                id="email"
                name="new"
                className="mt-1 block w-full px-3 text-center py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-purple-500 focus:border-transparent"
                autoComplete="off"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                defaultValue={formik.values.new}
              />
              {formik.errors.new && formik.touched.new && (
                <span className="text-red-500 text-sm">
                  {formik.errors.new}
                </span>
              )}
            </div>
            <div className="flex justify-center">
              <button
                type="submit"
                className={`flex  items-center w-1/3 bg-gradient-to-r from-blue-800 to-indigo-900 justify-center hover:bg-purple-700 text-white py-2 rounded-md focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-opacity-50 ${
                  isLoading ? "opacity-50 cursor-not-allowed" : ""
                }`}
                disabled={isLoading}
              >
                {isLoading ? <FaSpinner className="animate-spin mr-2" /> : null}
                Update
              </button>
            </div>
          </form>
        ) : (
          <>
            <label className="font-bold font-serif">Username:</label>
            <div className="mb-4">
              <input
                type="text"
                id="username"
                name="username"
                className="mt-1 cursor-no-drop block w-full px-3 text-center py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-purple-500 focus:border-transparent"
                readOnly
                disabled
                value={userData?.username}
              />
            </div>
            <label className="font-bold font-serif">Email:</label>
            <div className="mb-4">
              <input
                type="text"
                id="email"
                name="email"
                className="mt-1 cursor-no-drop block w-full px-3 text-center py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-purple-500 focus:border-transparent"
                readOnly
                disabled
                value={userData?.email}
              />
            </div>
            <div className="mb-5">
              <div className="mb-2">
                <label className="font-bold font-serif">Password:</label>
              </div>
              <button
                type="button"
                className=" text-blue-500"
                onClick={() => setIspasswordChanges(true)}
              >
                Change Password
              </button>
            </div>
            <div>
              <div className="mb-2">
                <label className="font-bold font-serif">Privacy:</label>
              </div>
              <button
                type="button"
                className=" text-red-500"
                onClick={handleDeleteUser}
              >
                Delete Account
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ProfileModal;
