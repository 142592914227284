import { useFormik } from "formik";
import React, { useState } from "react";
import Apiutils from "../../api/Apiutils";
import Toaster from "../../Components/Toaster";
import RepoDetails from "./RepoDetails";
import ActionButton from "../../Components/ActionButton";

const AddRepositoryModal = ({ isOpen, closeModal, repoList }) => {
  const [isLoading, setisLoading] = useState(false);
  const [addFlag, setAddFlag] = useState(false);
  const [repoDetails, setRepoDetails] = useState();
  const formik = useFormik({
    initialValues: {
      url: "",
      name: "",
    },
    onSubmit: (values) => {
      setisLoading(true);
      const apiData = {
        name: values.name,
        source_url: values.url,
      };
      Apiutils.addRepo(apiData)
        .then((res) => {
          if (res?.data?.data?.status === 3) {
            closeModal();
            formik.resetForm();
            setisLoading(false);
            Toaster("This Repositry Already Added", "info");
          } else {
            setRepoDetails(res?.data?.data);
            setisLoading(false);
            repoList();
            setAddFlag(true);
          }
        })
        .catch((err) => {
          Toaster(err?.data?.message, "error");
          setisLoading(false);
        });
    },
  });

  const handleUrlChange = (event) => {
    const url = event.target.value;
    const urlParts = url.split("/");
    const repoName = urlParts[urlParts.length - 1].replace(".git", "");
    formik.setFieldValue("url", url);
    formik.setFieldValue("name", repoName);
  };

  const handleCloseModal = () => {
    formik.resetForm();
    closeModal();
    setAddFlag(false);
  };

  return (
    <div
      className={`fixed inset-0 z-50 bg-black bg-opacity-50 flex flex-col items-center justify-center ${
        isOpen ? "" : "hidden"
      }`}
    >
      <div className="bg-white rounded-t-lg flex justify-between shadow-2xl max-w-md w-full p-4">
        <h2 className="text-xl font-bold">
          {addFlag ? repoDetails?.name : "Add Repository"}
        </h2>
        <div className="text-2xl cursor-pointer" onClick={handleCloseModal}>
          x
        </div>
      </div>
      <div className="bg-white mt-[1px] rounded-b-lg shadow-lg max-w-md w-full p-4">
        {addFlag ? (
          <RepoDetails
            repoDetails={repoDetails}
            handleCloseModal={handleCloseModal}
            getRepoList={repoList}
          />
        ) : (
          <form onSubmit={formik.handleSubmit}>
            <div className="mb-4">
              <input
                type="text"
                id="url"
                name="url"
                className="mt-1 block w-full px-3 text-center py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-purple-500 focus:border-transparent"
                placeholder="Repository URL"
                autoComplete="off"
                required
                onChange={handleUrlChange}
                onBlur={formik.handleBlur}
                value={formik.values.url}
              />
            </div>
            <div className="mb-6">
              <input
                type="text"
                id="name"
                name="name"
                className="mt-1 block w-full px-3 text-center py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-purple-500 focus:border-transparent"
                placeholder="Name"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.name}
              />
            </div>
            <div className="flex justify-center">
              <ActionButton
                onClick={handleCloseModal}
                disabled={false}
                className="w-1/3 py-2 px-4 rounded-md mr-2 border border-blue-800 text-gray-800 hover:text-gray-900"
              >
                Cancel
              </ActionButton>

              <ActionButton
                type="submit"
                isLoading={isLoading}
                disabled={isLoading}
                className="w-1/3 bg-gradient-to-r from-blue-800 to-indigo-900 hover:bg-purple-700 text-white"
              >
                Add
              </ActionButton>
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export default AddRepositoryModal;
