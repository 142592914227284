import React from "react";
import { useNavigate } from "react-router-dom";

const Home = () => {
  const navigate = useNavigate();
  return (
    <div className="font-sans antialiased bg-white text-gray-800">
      <div className="top-bar bg-white text-black flex justify-between shadow-sm items-center py-4 px-6">
        <div
          className="flex items-center gap-3 cursor-pointer"
          onClick={() => navigate("/")}
        >
          <img
            src="https://gitfront.io/gitfront.svg"
            alt="Company Logo"
            className="w-10 h-10 mr-2"
          />
          <h1 className="text-lg text-bl">GIT CLONE</h1>
        </div>
        <nav className="top-nav flex gap-4">
          {/* <a href="/pricing" className="top-link">
            Pricing
          </a> */}
          <button
            onClick={() => navigate("/login")}
            className="top-action btn btn-special text-white  ring-2 ring-teal-200 w-24 h-8 bg-gradient-to-r from-blue-800 to-indigo-900 rounded-md"
          >
            Login
          </button>
        </nav>
      </div>
      <section className="content grid grid-cols-2 gap-8 min-h-screen max-w-screen-lg mx-auto py-16 px-4">
        <h1 className="text-4xl font-semibold text-center col-span-2 mb-8">
          Share git repositories
        </h1>
        <div className="left mt-16">
          <p className="text-lg mb-8">
            Sometimes you want to show your personal git projects to friends,
            associates, or potential employers.
          </p>
          <p className="text-lg mb-8">
            With GitFront, you can share private git repositories without making
            them public.
          </p>
          <p className="text-lg mb-8">
            Even if you want to share public repositories, you can take
            advantage of GitFront's fast repository browser and clean user
            interface.
          </p>
        </div>
        <div className="right">
          <div className="try bg-white rounded-xl shadow-2xl p-8 h-96 text-center">
            <h2 className="text-3xl font-bold mt-16 mb-8">Share code now!</h2>
            <button
              onClick={() => navigate("/register")}
              className="inline-block text-2xl uppercase px-8 py-3 min-w-32 w-60 text-center cursor-pointer border border-transparent bg-gradient-to-r from-blue-800 to-indigo-900 text-white rounded-md"
            >
              Get Started
            </button>
            <p className="mt-4">Create Your Account and start sharing</p>
            <div className="console-link mt-8 mb-8 text-sm text-center">
              Already signed up? Continue to{" "}
              <button
                onClick={() => navigate("/login")}
                className="text-blue-500 hover:underline"
              >
                Login
              </button>
              .
            </div>
          </div>
        </div>
      </section>{" "}
      <section className="traits py-20 px-4 bg-white">
        <div className="max-w-4xl mx-auto grid grid-cols-1 md:grid-cols-3 gap-8">
          <div className="trait-item text-center">
            <img
              src="https://gitfront.io/fast.svg"
              alt="fast"
              className="w-24 h-24 mx-auto mb-4"
            />
            <h3 className="text-xl font-semibold mb-2">Fast</h3>
            <p>Speed matters. One of the fastest online repository browsers.</p>
          </div>
          <div className="trait-item text-center">
            <img
              src="https://gitfront.io/compatible.svg"
              alt="compatible"
              className="w-24 h-24 mx-auto mb-4"
            />
            <h3 className="text-xl font-semibold mb-2">Compatible</h3>
            <p>Works with all code hosting platforms.</p>
          </div>
          <div className="trait-item text-center">
            <img
              src="https://gitfront.io/beautiful.svg"
              alt="beautiful"
              className="w-24 h-24 mx-auto mb-4"
            />
            <h3 className="text-xl font-semibold mb-2">Beautiful</h3>
            <p>
              Without messy code history. No distractions, only presentation.
            </p>
          </div>
          <div className="trait-item text-center">
            <img
              src="https://gitfront.io/simple.svg"
              alt="simple"
              className="w-24 h-24 mx-auto mb-4"
            />
            <h3 className="text-xl font-semibold mb-2">Simple</h3>
            <p>Easy to import, shareable as an ordinary link.</p>
          </div>
          <div className="trait-item text-center">
            <img
              src="https://gitfront.io/secure.svg"
              alt="secure"
              className="w-24 h-24 mx-auto mb-4"
            />
            <h3 className="text-xl font-semibold mb-2">Secure</h3>
            <p>Only you have write access to your repository.</p>
          </div>
          <div className="trait-item text-center">
            <img
              src="https://gitfront.io/private.svg"
              alt="private"
              className="w-24 h-24 mx-auto mb-4"
            />
            <h3 className="text-xl font-semibold mb-2">Private</h3>
            <p>You choose what you share and who you share with.</p>
          </div>
        </div>
      </section>
      <section className="py-20 px-4">
        <div className="content max-w-4xl mx-auto">
          <div className="description-standalone max-w-2xl mx-auto text-center">
            <h2 className="text-3xl font-semibold mb-4">
              Remove obstacles for people you share code with
            </h2>
            <p className="text-lg">
              It may take a minute of your time to share a repository. It
              shouldn't take more than a few seconds to view it. Other platforms
              are for collaboration. GitFront is for presentation.
            </p>
          </div>
        </div>
      </section>
      <section className="demo py-20 px-4 bg-white">
        <div className="max-w-4xl mx-auto grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
          <video autoPlay loop muted className="w-full shadow-lg rounded-lg">
            <source
              src="https://gitfront-cdn.ams3.cdn.digitaloceanspaces.com/video/console-demo.webm"
              type="video/webm"
            />
            <source
              src="https://gitfront-cdn.ams3.cdn.digitaloceanspaces.com/video/console-demo.mp4"
              type="video/mp4"
            />
          </video>
          <div className="description max-w-md mx-auto">
            <h2 className="text-3xl font-semibold mb-4">
              All you need is one minute
            </h2>
            <p className="text-lg">
              Import a git repository. If it's private, copy and paste the
              deploy key. Click build and take a deep breath.
            </p>
            <p className="text-lg mt-4">Send a link.</p>
          </div>
        </div>
      </section>
      <footer className="py-12 px-4 bg-white text-black">
        <div className="max-w-4xl mx-auto flex flex-col md:flex-row items-center justify-center">
          <div className="links flex flex-wrap gap-4">
            <a href="/terms" className="hover:text-blue-500">
              Terms
            </a>
            <a href="/privacy" className="hover:text-blue-500">
              Privacy
            </a>

            <a
              href="https://gitclone.tecmantras.com/login"
              className="hover:text-blue-500"
            >
              Console
            </a>
          </div>
          <div className="copyright mt-4 ml-2 md:mt-0">
            © {new Date().getFullYear()} Gitclone
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Home;
