import React, { useState, useEffect, useRef } from "react";
import { FaUser, FaSignOutAlt } from "react-icons/fa";
import { setLoginAuth } from "../redux/slices/auth/loginSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ProfileModal from "./ProfileModal";

const Header = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const dropdownRef = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const data = useSelector((state) => state.loginSlice.login);
  const isProtectedRoute = data;

  const handleLogout = () => {
    localStorage.removeItem("gitToken");
    dispatch(setLoginAuth(""));
  };

  const toggleDropdown = () => {
    setIsDropdownOpen((prev) => !prev);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const username = JSON.parse(localStorage.getItem("gitToken"));
  const avatarInitial = username?.userName
    ? username?.userName.charAt(0).toUpperCase()
    : "";

  const handleProfile = () => {
    setIsModalOpen(true);
    setIsDropdownOpen(false);
  };

  return (
    <header
      className={`${
        isProtectedRoute ? "" : "absolute"
      } top-0 left-0 w-full h-20 bg-gradient-to-r from-blue-800 to-indigo-900 py-4 px-6 flex items-center justify-between`}
    >
      <div
        className="flex items-center gap-3 cursor-pointer"
        onClick={() => navigate("/")}
      >
        <img
          src="https://gitfront.io/gitfront.svg"
          alt="Company Logo"
          className="w-10 h-10 mr-2"
        />
        <h1 className="text-lg text-white">GIT CLONE</h1>
      </div>
      {isProtectedRoute && (
        <div className="relative" ref={dropdownRef}>
          <button
            onClick={toggleDropdown}
            className="flex items-center space-x-2 text-white focus:outline-none"
          >
            <div className="w-10 h-10 rounded-full ring-2 ring-teal-200 bg-gradient-to-r from-blue-800 to-indigo-900 flex items-center justify-center text-white font-bold">
              {avatarInitial}
            </div>
          </button>
          {isDropdownOpen && (
            <div className="absolute right-0 mt-5 w-40 bg-white rounded-md shadow-lg md:shadow-xl lg:shadow-2xl xl:shadow-3xl action">
              <div className="py-1 menu">
                <button
                  onClick={handleProfile}
                  className="block px-4 w-full py-2 text-sm text-gray-800 hover:bg-gray-100"
                >
                  <FaUser className="inline-block mr-2" /> Profile
                </button>

                <button
                  onClick={handleLogout}
                  className="block px-4 py-2 text-sm w-full text-gray-800 hover:bg-gray-100"
                >
                  <FaSignOutAlt className="inline-block mr-2" /> Logout
                </button>
              </div>
            </div>
          )}
        </div>
      )}

      <ProfileModal isOpen={isModalOpen} setOpenModal={setIsModalOpen} />
    </header>
  );
};

export default Header;
