import React from "react";
import { FaSpinner } from "react-icons/fa";

const ActionButton = ({
  onClick,
  disabled,
  isLoading,
  children,
  className,
  buttonStyle,
  type,
}) => (
  <button
    onClick={onClick}
    type={type}
    className={`flex items-center justify-center py-2 rounded-md focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-opacity-50 ${
      disabled ? "opacity-50 cursor-not-allowed" : ""
    } ${className}`}
    style={buttonStyle}
    disabled={disabled}
  >
    {isLoading ? <FaSpinner className="animate-spin mr-2" /> : null}
    {children}
  </button>
);

export default ActionButton;
