import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { authRoutes, publicRoutes } from "./index";
import { useSelector } from "react-redux";
import PageNotFound from "../Components/PageNotFound";
import Home from "../Pages/HomePage/Home";

const AppRoutes = () => {
  const data = useSelector((state) => state.loginSlice.login);
  const isProtectedRoute = data ? true : false;

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route
          path="/"
          element={
            isProtectedRoute ? (
              <Navigate to="/dashboard" replace />
            ) : (
              <Navigate to="/" replace />
            )
          }
        />
        {publicRoutes.map((route, idx) => {
          let id = idx * Math.floor(Math.random() * 10);
          return (
            <Route
              key={id}
              path={route.path}
              element={
                isProtectedRoute ? (
                  <Navigate
                    to="/dashboard"
                    replace
                    state={{ from: route.path }}
                  />
                ) : (
                  <route.component />
                )
              }
            />
          );
        })}

        {authRoutes.map((route, idx) => {
          let id = idx * Math.floor(Math.random() * 10);
          return (
            <Route
              key={id}
              path={route.path}
              element={
                !isProtectedRoute ? (
                  <Navigate to="/login" replace state={{ from: route.path }} />
                ) : (
                  <route.component />
                )
              }
            />
          );
        })}
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </Router>
  );
};

export default AppRoutes;
