import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../../Components/Header";
import { useFormik } from "formik";
import * as Yup from "yup";
import Apiutils from "../../api/Apiutils";
import Toaster from "../../Components/Toaster";
import { FaSpinner } from "react-icons/fa";

const Register = () => {
  const navigate = useNavigate();
  const [isLoading, setisLoading] = useState(false);
  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("User Name is required"),
      email: Yup.string()
        .email("Invalid email address")
        .required("Email is required"),
      password: Yup.string()
        .required("Password is required")
        .min(6, "Password must be at least 6 characters"),
    }),
    onSubmit: (values) => {
      const apiData = {
        username: values.name,
        email: values.email,
        password: values.password,
      };
      setisLoading(true);
      Apiutils.register(apiData)
        .then((res) => {
          Toaster(res?.data?.message, "success");
          navigate("/login");
          setisLoading(false);
        })
        .catch((err) => {
          Toaster(err?.data?.message, "error");
          setisLoading(false);
        });
    },
  });
  return (
    <div className="flex flex-col bg-gray-800 bg-opacity-45 items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
      <Header />

      <div className="bg-white rounded-t-lg shadow-lg max-w-md w-full p-4">
        <h2 className="text-xl font-bold mb-2 text-gray-800">Sign up</h2>
      </div>
      <div className="bg-white mt-[1px] rounded-b-lg shadow-lg max-w-md w-full p-4">
        <form onSubmit={formik.handleSubmit}>
          <div className="mb-4">
            <input
              type="text"
              id="name"
              name="name"
              className={`mt-1 block w-full px-3 text-center py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-purple-500 focus:border-transparent ${
                formik.errors.name && formik.touched.name
                  ? "border-red-500"
                  : "border-gray-300"
              }`}
              placeholder="User Name"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.name}
            />
            {formik.errors.name && formik.touched.name && (
              <span className="text-red-500 text-sm">{formik.errors.name}</span>
            )}
          </div>
          <div className="mb-4">
            <input
              type="email"
              id="email"
              name="email"
              className={`mt-1 block w-full px-3 text-center py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-purple-500 focus:border-transparent ${
                formik.errors.email && formik.touched.email
                  ? "border-red-500"
                  : "border-gray-300"
              }`}
              placeholder="Email"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email}
              autoComplete="off"
            />
            {formik.errors.email && formik.touched.email && (
              <span className="text-red-500 text-sm">
                {formik.errors.email}
              </span>
            )}
          </div>
          <div className="mb-6">
            <input
              type="password"
              id="password"
              name="password"
              className={`mt-1 block w-full px-3 text-center py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-purple-500 focus:border-transparent ${
                formik.errors.password && formik.touched.password
                  ? "border-red-500"
                  : "border-gray-300"
              }`}
              placeholder="Password"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.password}
            />
            {formik.errors.password && formik.touched.password && (
              <span className="text-red-500 text-sm">
                {formik.errors.password}
              </span>
            )}
          </div>
          <div className="flex justify-center">
            <button
              type="submit"
              className={`flex items-center w-1/4 bg-gradient-to-r from-blue-800 to-indigo-900 justify-center hover:bg-purple-700 text-white py-2 rounded-md focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-opacity-50 ${
                isLoading ? "opacity-50 cursor-not-allowed" : ""
              }`}
            >
              {isLoading ? <FaSpinner className="animate-spin mr-2" /> : null}
              Sign Up
            </button>
          </div>
        </form>
        <p className="mt-4 text-sm text-center text-gray-600">
          have an account?{" "}
          <button
            onClick={() => navigate("/login")}
            className="text-purple-500 hover:underline"
          >
            Login
          </button>
        </p>
      </div>
    </div>
  );
};

export default Register;
