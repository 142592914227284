import React, { useState, useEffect } from "react";
import OtpInput from "react-otp-input";
import Toaster from "../../Components/Toaster";
import Apiutils from "../../api/Apiutils";
import { useDispatch } from "react-redux";
import { setLoginAuth } from "../../redux/slices/auth/loginSlice";
import ActionButton from "../../Components/ActionButton";

const OtpModal = ({ isOpen, closeModal, userId }) => {
  const [otp, setOtp] = useState("");
  const [timer, setTimer] = useState(60);
  const [isResendDisabled, setIsResendDisabled] = useState(true);
  const [isLoading, setisLoading] = useState(false);
  const [isResendLoading, setIsResendLoading] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    let interval = null;

    if (timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else {
      setIsResendDisabled(false);
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [timer]);

  const handleOtpChange = (value) => {
    setOtp(value);
  };

  const handleVerifyOtp = () => {
    if (otp.length < 6) {
      Toaster("Plase Enter OTP", "warning");
    } else {
      const apiData = {
        id: userId,
        otp: otp,
      };
      setisLoading(true);
      Apiutils.varifyOtp(apiData)
        .then((res) => {
          Toaster(res?.data?.message, "success");
          dispatch(setLoginAuth(res?.data));
          localStorage.setItem("gitToken", JSON.stringify(res?.data));
          setisLoading(false);
          closeModal();
        })
        .catch((err) => {
          Toaster(err.data.message, "error");
          setisLoading(false);
        });
    }
  };

  const handleResendOtp = () => {
    setIsResendLoading(true);
    Apiutils.resendOtp({ id: userId })
      .then((res) => {
        Toaster(res?.data?.message, "success");
        setTimer(60);
        setIsResendDisabled(true);
        setIsResendLoading(false);
      })
      .catch((err) => {
        Toaster(err?.data?.message, "error");
        setIsResendLoading(false);
      });
  };

  return (
    <div
      className={`fixed inset-0 z-50 bg-black bg-opacity-50 flex flex-col items-center justify-center ${
        isOpen ? "" : "hidden"
      }`}
    >
      <div className="bg-white rounded-t-lg flex justify-between shadow-lg max-w-md w-full p-2">
        <h2 className="text-xl font-bold mb-4">Enter OTP</h2>
        <div className="text-2xl cursor-pointer" onClick={() => closeModal()}>
          x
        </div>
      </div>
      <div className="bg-white mt-[1px] rounded-b-lg shadow-lg max-w-md w-full p-4">
        <OtpInput
          value={otp}
          onChange={handleOtpChange}
          numInputs={6}
          renderSeparator={<span>-</span>}
          renderInput={(props, index) => (
            <input
              {...props}
              autoFocus={index === 0}
              style={{
                width: "2em",
                height: "2em",
                margin: "0 0.25em",
                textAlign: "center",
                fontSize: "1.5em",
                border: "1px solid black",
              }}
            />
          )}
        />
        <p className="mt-2 text-sm text-center text-gray-600">
          OTP sent to your email address.
        </p>
        <div className="flex justify-center mt-7 gap-3">
          <ActionButton
            onClick={handleVerifyOtp}
            isLoading={isLoading}
            disabled={isLoading}
            className=" w-1/3 bg-gradient-to-r from-blue-800 to-indigo-900 hover:bg-purple-700 text-white"
          >
            Verify OTP
          </ActionButton>

          <ActionButton
            onClick={handleResendOtp}
            disabled={isResendDisabled}
            isLoading={isResendLoading}
            className=" py-2 px-4 rounded-md mr-2 border border-blue-800 text-gray-800 hover:text-gray-900"
          >
            Resend OTP {timer > 0 && `(${timer} sec)`}
          </ActionButton>
        </div>
      </div>
    </div>
  );
};

export default OtpModal;
