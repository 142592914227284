import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Apiutils from "../../api/Apiutils";
import Toaster from "../../Components/Toaster";

const ActivationPage = () => {
  const { token } = useParams();
  const [isVarified, setIsVarified] = useState();

  useEffect(() => {
    Apiutils.activeUser(token)
      .then((res) => {
        Toaster(res?.data?.message, "success");
        setIsVarified(true);
      })
      .catch((err) => {
        Toaster(err?.data.message, "error");
        setIsVarified(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="flex items-center justify-center min-h-screen bg-blue-200">
      <div className="bg-white p-10 rounded-lg shadow-2xl text-center">
        {isVarified ? (
          <>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-20 w-20 mx-auto text-white bg-green-500 rounded-full mb-4 animated-bg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                className="checkmark"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M5 13l4 4L19 7"
              />
            </svg>
            <h1 className="text-2xl text-green-300 font-bold mb-2">
              Verified!
            </h1>
            <p className="text-gray-600 mb-6">
              You have successfully verified your account.
            </p>
          </>
        ) : (
          <>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-20 w-20 mx-auto text-white bg-red-400 rounded-full mb-4 animated-bg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                className="cross"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="{2}"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
            <h1 className="text-2xl text-red-300 font-bold mb-2">
              Not Varified!
            </h1>
            <p className="text-gray-600 mb-6">
              Your account is not varified Please Try again latter...
            </p>
          </>
        )}
        <a
          href="/login"
          className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-700 focus:outline-none"
        >
          Back to Login
        </a>
      </div>
    </div>
  );
};

export default ActivationPage;
