import { END_POINTS } from "./apis";
import api from "./index";
const Apiutils = {
  register: async function (params) {
    try {
      const response = await api.post(END_POINTS.REGISTER, params);
      return response;
    } catch (error) {
      throw error.response;
    }
  },
  login: async function (params) {
    try {
      const response = await api.post(END_POINTS.LOGIN, params);
      return response;
    } catch (error) {
      throw error.response;
    }
  },
  varifyOtp: async function (params) {
    try {
      const response = await api.post(END_POINTS.VERIFY_OTP, params);
      return response;
    } catch (error) {
      throw error.response;
    }
  },
  resendOtp: async function (params) {
    try {
      const response = await api.post(END_POINTS.RESEND_OTP, params);
      return response;
    } catch (error) {
      throw error.response;
    }
  },
  sendResetLink: async function (params) {
    try {
      const response = await api.post(END_POINTS.SEND_RESET_LINK, params);
      return response;
    } catch (error) {
      throw error.response;
    }
  },
  resetPassword: async function (params) {
    try {
      const response = await api.post(END_POINTS.RESET_PASSWORD, params);
      return response;
    } catch (error) {
      throw error.response;
    }
  },
  addRepo: async function (params) {
    try {
      const response = await api.post(END_POINTS.ADD_REPO, params);
      return response;
    } catch (error) {
      throw error.response;
    }
  },
  generateSSH: async function (params) {
    try {
      const response = await api.post(END_POINTS.GENERATE_SSH_KEY, params);
      return response;
    } catch (error) {
      throw error.response;
    }
  },
  createBuild: async function (params) {
    try {
      const response = await api.post(END_POINTS.CREATE_BUILD, params);
      return response;
    } catch (error) {
      throw error.response;
    }
  },
  createReBuild: async function (params) {
    try {
      const response = await api.post(END_POINTS.REBUILD, params);
      return response;
    } catch (error) {
      throw error.response;
    }
  },
  deleteBuild: async function (params) {
    try {
      const response = await api.post(END_POINTS.DELETE_REPO, params);
      return response;
    } catch (error) {
      throw error.response;
    }
  },
  repoList: async function (params) {
    try {
      const response = await api.get(`${END_POINTS.REPO_LIST}?${params}`);
      return response;
    } catch (error) {
      throw error.response;
    }
  },
  repoData: async function (params) {
    try {
      const response = await api.get(`${params}`);
      return response;
    } catch (error) {
      throw error.response;
    }
  },
  repoContent: async function (params) {
    try {
      const response = await api.get(`${params}`);
      return response;
    } catch (error) {
      throw error.response;
    }
  },
  getProfile: async function () {
    try {
      const response = await api.get(END_POINTS.GET_PROFILE);
      return response;
    } catch (error) {
      throw error.response;
    }
  },
  changePassword: async function (params) {
    try {
      const response = await api.post(END_POINTS.CHANGE_PASSWORD, params);
      return response;
    } catch (error) {
      throw error.response;
    }
  },
  deleteAccount: async function () {
    try {
      const response = await api.get(END_POINTS.DELETE_USER);
      return response;
    } catch (error) {
      throw error.response;
    }
  },
  reActiveMailSend: async function (params) {
    try {
      const response = await api.post(END_POINTS.REACTIVE_MAIL_SEND, params);
      return response;
    } catch (error) {
      throw error.response;
    }
  },
  activeUser: async function (params) {
    try {
      const response = await api.get(
        `${END_POINTS.ACTIVE_USER_TOKEN}/${params}`
      );
      return response;
    } catch (error) {
      throw error.response;
    }
  },
};
export default Apiutils;
