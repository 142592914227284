import React, { useState, useEffect } from "react";

const Pagination = ({ totalItems, itemsPerPage, onPageChange }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const handlePageChange = (page) => {
    if (page < 1) {
      page = 1;
    } else if (page > totalPages) {
      page = totalPages;
    }
    setCurrentPage(page);
    onPageChange(page);
  };

  useEffect(() => {
    setCurrentPage(1);
  }, [totalItems, itemsPerPage]);

  const renderPaginationControls = () => {
    const MAX_VISIBLE_PAGES = 5; // Maximum number of visible page buttons

    const pageButtons = [];
    let startPage = 1;
    let endPage = totalPages;

    if (totalPages > MAX_VISIBLE_PAGES) {
      const halfMax = Math.floor(MAX_VISIBLE_PAGES / 2);
      startPage = Math.max(1, currentPage - halfMax);
      endPage = startPage + MAX_VISIBLE_PAGES - 1;
      if (endPage > totalPages) {
        endPage = totalPages;
        startPage = endPage - MAX_VISIBLE_PAGES + 1;
      }
    }

    for (let page = startPage; page <= endPage; page++) {
      pageButtons.push(
        <li key={page}>
          <button
            onClick={() => handlePageChange(page)}
            className={`px-4 text-center py-2 rounded-md ${
              currentPage === page
                ? "bg-gradient-to-r from-blue-800 to-indigo-900 text-white"
                : "bg-gray-200 hover:bg-gray-300"
            }`}
            style={{ maxWidth: "40px" }} // Set a fixed width for the button
          >
            {page}
          </button>
        </li>
      );
    }

    return pageButtons;
  };

  return (
    <nav className="flex justify-center mt-4">
      <ul className="flex space-x-2">
        <li>
          <button
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
            className={`px-4 py-2 rounded-md ${
              currentPage === 1
                ? "bg-gray-300 cursor-not-allowed"
                : "bg-gray-200 hover:bg-gray-300"
            }`}
          >
            Previous
          </button>
        </li>
        {renderPaginationControls()}
        <li>
          <button
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
            className={`px-4 py-2 rounded-md ${
              currentPage === totalPages
                ? "bg-gray-300 cursor-not-allowed"
                : "bg-gray-200 hover:bg-gray-300"
            }`}
          >
            Next
          </button>
        </li>
      </ul>
    </nav>
  );
};

export default Pagination;
