// StatusBadge.js
import React from "react";

const StatusBadge = ({ status }) => {
  const getStatusInfo = (status) => {
    switch (status) {
      case 0:
        return {
          text: "Pending",
          color:
            "bg-yellow-100 text-yellow-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded  border border-yellow-300",
        };
      case 1:
        return {
          text: "Processing",
          color:
            "bg-purple-100 text-purple-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded border border-purple-400",
        };
      case 2:
        return {
          text: "Building",
          color:
            "bg-red-100 text-red-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded border border-red-400",
        };
      case 3:
        return {
          text: "Up",
          color:
            "bg-green-100 text-green-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded border border-green-400",
        };
      case 4:
        return {
          text: "Cancel",
          color:
            "bg-red-100 text-red-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded border border-red-400",
        };
      default:
        return {
          text: "Unknown",
          color:
            "bg-gray-100 text-gray-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded border border-gray-400",
        };
    }
  };

  const { text, color } = getStatusInfo(status);

  return <span className={`px-2 py-1 rounded ${color}`}>{text}</span>;
};

export default StatusBadge;
