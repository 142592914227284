export const END_POINTS = {
  REGISTER: "/register",
  LOGIN: "/login",
  VERIFY_OTP: "/verify-otp",
  RESEND_OTP: "/resend-otp",
  SEND_RESET_LINK: "/send-reset-link",
  ADD_REPO: "/add-repo",
  RESET_PASSWORD: "/reset-password",
  GENERATE_SSH_KEY: "/generate-ssh-key",
  CREATE_BUILD: "/create-build",
  REPO_LIST: "/list-repo",
  REBUILD: "/rebuild",
  DELETE_REPO: "/delete-build",
  GET_PROFILE: "/get-profile",
  CHANGE_PASSWORD: "/change-password",
  DELETE_USER: "/deactive-user",
  REACTIVE_MAIL_SEND: "/send-active-mail",
  ACTIVE_USER_TOKEN: "/active-user-token",
};