import moment from "moment";
import React, { useState } from "react";
import Toaster from "../../Components/Toaster";
import Apiutils from "../../api/Apiutils";
import ActionButton from "../../Components/ActionButton";

const ViewModal = ({ isOpen, setModalClose, repoDetails, repoList }) => {
  console.log("🚀 ~ ViewModal ~ repoDetails:", repoDetails);
  const [isBuildLoading, setisBuildLoading] = useState(false);
  const [isDeleteLoading, setisDeleteLoading] = useState(false);
  const [isLoading, setisLoading] = useState(false);
  const [isSssh, setisSsh] = useState(false);
  const [sshKey, setSshKey] = useState();

  const handleCloseModal = () => {
    setModalClose(false);
    setisSsh(false);
  };

  const handleView = () => {
    window.open(
      `${process.env.REACT_APP_REDIRECT_URL}/${repoDetails?.name}/${repoDetails?.repo_token}`
    );
  };

  function convertSshUrlToSettingsUrl(sshUrl) {
    // Extract the username and repository from the SSH URL
    const repoPath = sshUrl.replace("git@github.com:", "").replace(".git", "");

    // Construct the new URL
    const settingsUrl = `https://github.com/${repoPath}/settings/keys/new#new_public_key`;

    return settingsUrl;
  }

  const handleReBuild = () => {
    setisBuildLoading(true);
    Apiutils.createReBuild({ id: repoDetails?.id })
      .then((res) => {
        Toaster(res?.data?.message, "success");
        setisBuildLoading(false);
        repoList();
        handleCloseModal();
      })
      .catch((err) => {
        Toaster(err?.data?.message, "error");
        setisBuildLoading(false);
      });
  };

  const handleDeleteRepo = () => {
    setisDeleteLoading(true);
    Apiutils.deleteBuild({ id: repoDetails?.id })
      .then((res) => {
        Toaster(res?.data?.message, "success");
        setisDeleteLoading(false);
        repoList();
        handleCloseModal();
      })
      .catch((err) => {
        Toaster(err?.data?.message, "error");
        setisDeleteLoading(false);
      });
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(repoDetails?.ssh_key || sshKey);
    Toaster("SSH key copied to clipboard", "success");
  };

  const handleClose = () => {
    handleCloseModal();
    setisSsh(false);
  };

  const handleBuild = () => {
    setisBuildLoading(true);
    Apiutils.createBuild({ id: repoDetails?.id })
      .then((res) => {
        Toaster(res?.data?.message, "success");
        setisBuildLoading(false);
        repoList();
        handleCloseModal();
      })
      .catch((err) => {
        Toaster(err?.data?.message, "error");
        setisBuildLoading(false);
        repoList();
      });
  };

  const handleSsh = () => {
    setisLoading(true);
    Apiutils.generateSSH({ id: repoDetails?.id })
      .then((res) => {
        setSshKey(res?.data?.data?.ssh_key);
        repoList();
        setisLoading(false);
        setisSsh(true);
        Toaster(res?.data?.message, "success");
      })
      .catch((err) => {
        Toaster(err?.data?.message, "error");
        setisLoading(false);
      });
  };

  return (
    <div
      className={`fixed inset-0 z-50 bg-black bg-opacity-50 flex flex-col items-center justify-center ${
        isOpen ? "" : "hidden"
      }`}
    >
      <div className="bg-white rounded-t-lg flex justify-between shadow-2xl max-w-md w-full p-4">
        <h2 className="text-xl font-bold">{repoDetails?.name}</h2>
        <div className="text-2xl cursor-pointer" onClick={handleCloseModal}>
          x
        </div>
      </div>
      <div className="bg-white mt-[1px] rounded-b-lg shadow-lg max-w-md w-full p-4">
        <div className="flex flex-col space-y-2 ">
          <p>
            <span className="font-semibold">Name:</span> {repoDetails?.name}
          </p>
          <p>
            <span className="font-semibold">Source URL:</span>{" "}
            {repoDetails?.source_url}
          </p>
          <p>
            <span className="font-semibold">Created:</span>{" "}
            {moment(repoDetails?.created_at).format("DD-MM-YYYY h:mm A")}
          </p>
          <p>
            <span className="font-semibold">Updated:</span>{" "}
            {moment(repoDetails?.updated_at).format("DD-MM-YYYY h:mm A")}
          </p>
          {repoDetails?.size && (
            <p>
              <span className="font-semibold">Size:</span> {repoDetails?.size}
            </p>
          )}
        </div>
        {(() => {
          if (
            (repoDetails?.type === 1 && repoDetails?.status === 0) ||
            (repoDetails?.ssh_status === 1 && repoDetails?.status === 1) ||
            repoDetails?.status === 2
          ) {
            return (
              <div className="flex justify-center space-x-4 mt-8">
                <ActionButton
                  onClick={handleClose}
                  disabled={false}
                  className="w-1/3 py-2 px-4 rounded-md mr-2 border border-blue-800 text-gray-800 hover:text-gray-900"
                >
                  Cancel
                </ActionButton>
                <ActionButton
                  onClick={handleBuild}
                  disabled={isBuildLoading}
                  isLoading={isBuildLoading}
                  className="w-1/3 bg-gradient-to-r from-blue-800 to-indigo-900 hover:bg-purple-700 text-white"
                >
                  Build
                </ActionButton>
                <ActionButton
                  onClick={handleDeleteRepo}
                  disabled={isDeleteLoading}
                  isLoading={isDeleteLoading}
                  className="w-1/3 py-2 px-4 rounded-md border border-blue-800 text-gray-800 hover:text-gray-900"
                >
                  Remove
                </ActionButton>
              </div>
            );
          } else if (
            (repoDetails?.ssh_key !== null && repoDetails?.status === 1) ||
            isSssh
          ) {
            return (
              <>
                <textarea
                  className="w-full text-xs h-28 overflow-auto mt-5 resize-none p-2 border border-gray-200 rounded-md focus:outline-none focus:ring-1 focus:border-blue-800"
                  value={repoDetails?.ssh_key || sshKey}
                  readOnly
                />
                <div className="flex justify-center space-x-4 mt-8">
                  <ActionButton
                    onClick={handleCopy}
                    disabled={isBuildLoading}
                    isLoading={isBuildLoading}
                    className="w-1/3 bg-gradient-to-r from-blue-800 to-indigo-900 hover:bg-purple-700 text-white"
                  >
                    Copy SSH
                  </ActionButton>
                </div>
                <div className="flex justify-center mt-4 mb-4 text-blue-800 hover:text-purple-700">
                  <a
                    href={convertSshUrlToSettingsUrl(repoDetails?.source_url)}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Open repository Deploy keys on GitHub.
                  </a>
                </div>
                <div className="flex justify-center space-x-4 mt-5">
                  <ActionButton
                    onClick={handleClose}
                    disabled={false}
                    className="w-1/3 py-2 px-4 rounded-md border border-blue-800 text-gray-800 hover:text-gray-900"
                  >
                    Cancel
                  </ActionButton>
                  <ActionButton
                    onClick={handleBuild}
                    disabled={isBuildLoading}
                    isLoading={isBuildLoading}
                    className="w-1/3 bg-gradient-to-r from-blue-800 to-indigo-900 hover:bg-purple-700 text-white"
                  >
                    Build
                  </ActionButton>
                  <ActionButton
                    onClick={handleDeleteRepo}
                    disabled={isDeleteLoading}
                    isLoading={isDeleteLoading}
                    className="w-1/3 py-2 px-4 rounded-md border border-blue-800 text-gray-800 hover:text-gray-900"
                  >
                    Remove
                  </ActionButton>
                </div>
              </>
            );
          } else if (
            repoDetails?.status === 0 &&
            repoDetails?.ssh_key === null
          ) {
            return (
              <div className="flex justify-center space-x-4 mt-8">
                <ActionButton
                  onClick={handleClose}
                  disabled={false}
                  className="w-1/3 py-2 px-4 rounded-md border border-blue-800 text-gray-800 hover:text-gray-900"
                >
                  Cancel
                </ActionButton>
                <ActionButton
                  onClick={handleSsh}
                  disabled={isLoading}
                  isLoading={isLoading}
                  className="w-1/3 bg-gradient-to-r from-blue-800 to-indigo-900 hover:bg-purple-700 text-white"
                >
                  Convert to SSH
                </ActionButton>
                <ActionButton
                  onClick={handleDeleteRepo}
                  disabled={isDeleteLoading}
                  isLoading={isDeleteLoading}
                  className="w-1/3 py-2 px-4 rounded-md border border-blue-800 text-gray-800 hover:text-gray-900"
                >
                  Remove
                </ActionButton>
              </div>
            );
          } else {
            return (
              <div className="flex justify-between gap-4 mt-3">
                <ActionButton
                  onClick={handleView}
                  disabled={false}
                  className="w-full bg-gradient-to-r from-blue-800 to-indigo-900 hover:bg-purple-700 text-white"
                >
                  View
                </ActionButton>
                <ActionButton
                  onClick={handleReBuild}
                  disabled={isBuildLoading}
                  isLoading={isBuildLoading}
                  className="w-full bg-gradient-to-r from-blue-800 to-indigo-900 hover:bg-purple-700 text-white"
                >
                  Rebuild
                </ActionButton>
                <ActionButton
                  onClick={handleDeleteRepo}
                  disabled={isDeleteLoading}
                  isLoading={isDeleteLoading}
                  className="w-2/3 py-2 px-4 rounded-md border border-blue-800 text-gray-800 hover:text-gray-900"
                >
                  Remove
                </ActionButton>
              </div>
            );
          }
        })()}
      </div>
    </div>
  );
};

export default ViewModal;
