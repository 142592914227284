import React, { useState } from "react";
import Apiutils from "../../api/Apiutils";
import Toaster from "../../Components/Toaster";
import { RiLoader4Line } from "react-icons/ri"; // Assuming you are using React Icons for the spinner

const ReactivateModal = ({ isOpen, closeModal, values }) => {
  const [isLoading, setIsLoading] = useState(false);
  if (!isOpen) return null;

  const handleSendMail = () => {
    setIsLoading(true);
    Apiutils.reActiveMailSend({
      email: values?.email,
      password: values?.password,
    })
      .then((res) => {
        setIsLoading(false);
        closeModal();
        Toaster(res?.data?.message, "success");
      })
      .catch((err) => {
        setIsLoading(false);
        Toaster(err?.data?.message, "error");
      });
  };

  return (
    <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex items-center justify-center z-50">
      {isLoading && (
        <div className="absolute inset-0 flex items-center justify-center">
          <RiLoader4Line className="animate-spin text-black h-10 w-10" />
        </div>
      )}
      <div
        className={` ${
          isLoading ? "bg-gray-300" : "bg-white"
        } p-10 rounded-lg shadow-2xl text-center`}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-20 w-20 mx-auto text-white bg-red-400 rounded-full mb-4 animated-bg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            className="cross"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="{2}"
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
        <h1 className="text-2xl text-red-500 font-bold mb-2">
          Your account is Deactivated!
        </h1>
        <div className="flex items-center space-x-2 mb-6">
          <p className="text-gray-600 ">Reactivate Account?</p>
          <button
            onClick={handleSendMail}
            className="text-blue-500 hover:underline focus:outline-none"
          >
            Click here
          </button>
        </div>
        <button
          onClick={closeModal}
          className="bg-gray-300 text-gray-800 px-4 py-2 rounded hover:bg-gray-400"
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

export default ReactivateModal;
